.container, .container-fluid {
  margin-top: 20px;
  max-width: 4000px !important;
}

.box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.App {
  display: flex;
  width: 100%;
  align-items: stretch;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.alert_error {
  background-color: red !important;
  color: white !important;
}

.wrapper {
  position: relative;
  top: 0;
  height: 100vh;
}

.sidebar, body>.navbar-collapse {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 260px;
  display: block;
  z-index: 1;
  color: #fff;
  font-weight: 200;
  background-size: cover;
  background-position: 50%;
}

.sidebar:before, body>.navbar-collapse:before {
  opacity: .01;
  background: #000;
}
.sidebar:after, .sidebar:before, body>.navbar-collapse:after, body>.navbar-collapse:before {
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}

.sidebar .sidebar-background, body>.navbar-collapse .sidebar-background {
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  display: block;
  top: 0;
  left: 0;
  background-size: 100%;
  background-position-y: 88px;
  background-repeat: no-repeat;
  background-color: #f3a64a;
  background-image: url("images/jobb.png");

}

.sidebar .nav li .nav-link svg, body>.navbar-collapse .nav li .nav-link svg {
  font-size: 28px;
  margin-right: 15px;
  width: 30px;
  text-align: center;
  vertical-align: middle;
  float: left;
}

.sidebar .sidebar-wrapper, body>.navbar-collapse .sidebar-wrapper {
  position: relative;
  max-height: calc(100vh - 75px);
  min-height: 100%;
  overflow: auto;
  width: 260px;
  z-index: 4;
}

.sidebar .logo, body>.navbar-collapse .logo {
  padding: 10px 15px;
  border-bottom: 1px solid hsla(0,0%,100%,.2);
}

.sidebar .logo, body>.navbar-collapse .logo {
  padding: 10px 15px 9px;
  position: relative;
  z-index: 4;
}

.sidebar .logo .simple-text, body>.navbar-collapse .logo .simple-text {
  text-transform: uppercase;
  padding: 5px 0;
  display: block;
  font-size: 18px;
  color: #fff;
  text-align: center;
  font-weight: 400;
  line-height: 30px;
}

.sidebar .nav li.active .nav-link, body>.navbar-collapse .nav li.active .nav-link {
  color: #fff;
  opacity: 1;
  background: hsla(0,0%,100%,.23);
}
.sidebar .nav li .nav-link, body>.navbar-collapse .nav li .nav-link {
  color: #fff;
  margin: 5px 15px;
  opacity: .86;
  border-radius: 4px;
  display: block;
  padding: 10px 15px;
}
.sidebar .nav .nav-link:hover {
  background: hsla(0,0%,100%,.23);
}
.sidebar .nav .nav-link, body>.navbar-collapse .nav .nav-link {
  color: #fff;
  margin: 5px 15px;
  opacity: .86;
  border-radius: 4px;
  text-transform: uppercase;
  line-height: 30px;
  font-size: 12px;
  font-weight: 600;
}
.sidebar .nav a, .table>tbody>tr .td-actions .btn {
  transition: all .15s ease-in;
}
.sidebar .nav li .nav-link p, body>.navbar-collapse .nav li .nav-link p {
  margin: 0;
  line-height: 31px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  display: inline-flex;
}

.main-panel, .sidebar {
  overflow: auto;
  max-height: 100%;
  height: 100%;
  transition-property: top,bottom;
  transition-duration: .2s,.2s;
  transition-timing-function: linear,linear;
  -webkit-overflow-scrolling: touch;
}
.main-panel {
  background: rgba(203,203,210,.15);
  position: relative;
  float: right;
  width: calc(100% - 260px);
  min-height: 100%;
}

.main-panel-wide {
  background: rgba(203,203,210,.15);
  position: relative;
  float: right;
  width: 100%;
  height: 100%;
}
.page-navbar {
  border-bottom: 1px solid rgba(0,0,0,.1);
}

.page-navbar {
  height: 65px;
}
.page-navbar li.nav-item p {
 font-size: 16px;
}
.page-navbar li.nav-item a {
  cursor: pointer;
  color: #888;
  margin:0;
  padding: 0;
}

.page-navbar li.nav-item a:visited {
  color: #888;
}

.page-navbar li.nav-item a:hover {
  color: orange;
}

.page-navbar .nav li .nav-link span {
  margin: 0;
  line-height: 28px;
  font-size: 14px;
  text-transform: uppercase;
  display: inline-flex;
  font-family: "Roboto","Helvetica Neue",Arial,sans-serif;
  font-weight: 400;
}


.page-navbar .nav li .nav-link svg {
  font-size: 24px;
  margin-right: 5px;
  width: 30px;
  text-align: center;
  vertical-align: middle;
  float: left;
}


.main-panel .navbar {
  margin-bottom: 0;
}
.navbar {
  border: 0;
  font-size: 16px;
  border-radius: 0;
  min-height: 50px;
  background-color: hsla(0,0%,100%,.96);
  border-bottom: 1px solid rgba(0,0,0,.1);
}

div.card-title  {
  margin: 0;
  color: #797777;
  font-weight: 300;
  font-size: 22px;
  line-height: 30px;

  -webkit-font-smoothing: antialiased;
  font-family: "Roboto","Helvetica Neue",Arial,sans-serif;
  padding-left: 15px;
  padding-top: 15px;
  padding-bottom: 0;
  background-color: #fff;
  border-bottom: none!important;
}

div.modal-header h5 {
  margin: 0;
  color: #797777;
  font-weight: 300;
  font-size: 22px;
  line-height: 30px;
  -webkit-font-smoothing: antialiased;
  font-family: "Roboto","Helvetica Neue",Arial,sans-serif;
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 0;
  background-color: #fff;
  border-bottom: none!important;
}

div.card-subtitle , div.modal-body{
  font-size: 14px;
  font-weight: 400;
  color: #9a9a9a;
  padding-left: 15px;
  margin-bottom: 1.5rem;
}

div.modal-body {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

div.card-body {
  padding: 0;
}

.alert, .brand, .btn-simple, .h1, .h2, .h3, .h4, .h5, .h6, .navbar, .td-name, a, body, button.close, h1, h2, h3, h4, h5, h6, p, td {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: "Roboto","Helvetica Neue",Arial,sans-serif;
  font-weight: 400;
}
.h3, h3 {
  font-weight: 400 !important;
  font-size: 20px !important;
  color: #888;
  margin: 20px 0 10px;
}
.h1, .h2, .h3, .h4, h1, h2, h3, h4 {
  font-weight: 300;
  margin: 30px 0 15px;
}

.page-navbar h3 {
  margin-top: 0;
}

.table>thead>tr>th {
  font-size: 12px;
  text-transform: uppercase;
  color: #9a9a9a;
  font-weight: 400;
  padding-bottom: 5px;
  border-top: none!important;
  border-bottom: none;
  text-align: left!important;
}

.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
  padding: 12px 8px;
  vertical-align: middle;
}

tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

a.function {
  cursor: pointer;
  color: #888;
  margin:0;
  padding: 0;
  text-transform: uppercase;
  font-size: 12px;
}

a.function:visited {
  color: #888;
}

a.function:hover {
  color: orange;
  text-decoration: none;
}

a.button {
  cursor: pointer;
  color: #888;
  margin-left: 25px;
  margin-right: 5px;
  padding: 0;
  text-transform: uppercase;
  font-size: 16px;
}

a.button:visited {
  color: #888;
}

a.button:hover {
  color: orange;
  text-decoration: none;
}

label {
  font-size: 10px;
  text-transform: uppercase;
  margin-bottom: 0 !important;
  padding-bottom: 0;
}

.form-group{
  margin-bottom: 5px !important;

}

